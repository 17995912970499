import { createInertiaApp } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ru.js';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';
import { createApp, h } from 'vue';
import { ZiggyVue } from 'ziggy-js';
import AppLayout from './Admin/Layouts/AppLayout.vue';
import Theme from './Admin/Theme/index';
import './bootstrap';

const pinia = createPinia();
dayjs.locale('ru');

const permissionMixin = {
  methods: {
    userCan(permission) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return this.$page.props.auth.user.permissions.includes(permission);
    },
    userCanAny(permissions) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return permissions.some((permission) => this.userCan(permission));
    },
    userCanAll(permissions) {
      if (this.userHasRole('SuperAdmin')) {
        return true;
      }

      return permissions.every((permission) => this.userCan(permission));
    },
    userHasRole(role) {
      return this.$page.props.auth.user.roles.includes(role);
    },
    userHasAnyRole(roles) {
      return roles.some((role) => this.userHasRole(role));
    },
  },
};

createInertiaApp({
  title: (title) => `${title} - ZAKAZBILETOV.KZ`,
  resolve: async (name) => {
    const page = await resolvePageComponent(`./Admin/Views/${name}.vue`, import.meta.glob('./Admin/Views/**/*.vue'));
    page.default.layout = page.default.layout || AppLayout;
    return page;
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_VUE_SENTRY_DSN_PUBLIC,
      environment: import.meta.env.APP_ENV,
      ignoreErrors: [
        'Network Error',
        'AxiosError',
        'TypeError',
      ],
      enableTracing: false,
      trackComponents: true,
    });

    app.mixin(permissionMixin)
      .use(plugin)
      .use(pinia)
      .use(ZiggyVue)
      .use(PrimeVue, {
        ripple: true,
        unstyled: true,
        pt: Theme,
      })
      .use(ConfirmationService)
      .use(ToastService)
      .use(DialogService)
      .mount(el);

    return app;
  },
  progress: {
    color: '#FFFFFF',
  },
});
