export default {
    panel: {
        class: '!my-0'
    },
    header: {
      class: [
        'outline-none',
        'focus:outline-none focus:outline-offset-0 focus:shadow-none focus:bg-header', // Focus
      ],
    },
    headerContent: ({ context }) => ({
      class: [
        // 'py-2 px-4',
        '!border-none bg-transparent text-white rounded-none transition-shadow duration-200',
        'hover:bg-header hover:text-white',
      ],
    }),
    headerLink: {
      class: ['flex items-center select-none cursor-pointer relative no-underline', 'text-white p-5 font-bold'],
    },
    headerLabel: {
        class: 'leading-none'
    },
    headerIcon: {
        class: 'mr-2'
    },
    submenuIcon: {
        class: 'mr-2'
    },
    content: {
        class: [
            // Spacing
            'text-white',
        ]
    },
    rootList: {
      class: ['outline-none', 'm-0 p-0 list-none'],
    },
    menuitem: {
        class: 'relative my-[2px]'
    },
    itemContent: ({ context }) => ({
      class: [
        '!bg-transparent text-white',
        'hover:bg-header hover:text-white', // Hover
        {
          '!bg-transparent text-white': context.focused,
        },
      ],
    }),
    itemLink: ({ context }) => ({
      class: ['text-white py-2 px-5 select-none', 'flex items-center cursor-pointer no-underline relative overflow-hidden'],
    }),
    itemIcon: {
        class: 'mr-2'
    },
    submenu: {
        class: 'p-0 pl-4 m-0 list-none'
    },
    transition: {
        enterFromClass: 'max-h-0',
        enterActiveClass: 'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
        enterToClass: 'max-h-[1000px]',
        leaveFromClass: 'max-h-[1000px]',
        leaveActiveClass: 'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
        leaveToClass: 'max-h-0'
    }
};
