export default {
  root: ({ context }) => {

    return {
      class: [
        'items-center cursor-pointer flex overflow-hidden relative select-none text-decoration-none select-none', // Flex and overflow styles.
        'border-b-2 p-3 font-bold rounded-t-md transition-shadow duration-200 m-0', // Border, padding, font, and transition styles.
        'transition-colors duration-200', // Transition duration style.
        'focus:outline-none focus:outline-offset-0 focus:shadow-[inset_0_0_0_0.2rem_rgba(191,219,254,1)]', // Focus styles.
        {
          'border-gray-300 bg-white text-gray-700 hover:bg-white hover:border-gray-400 hover:text-gray-600': !context.active, // Condition-based hover styles.
          'bg-white border-blue-500 text-blue-500': context.active, // Condition-based active styles.
        },
        '-mb-0.5',
      ],
    };
  },
};
