<template>
  <nav
    class="fixed inset-x-0 top-0 z-50 border-b-2 border-subheader bg-header px-4 py-2.5 text-white"
  >
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex w-full items-center justify-start md:justify-between">
        <button
          class="text-white-600 hover:text-white-900 hover:bg-white-100 focus:bg-white-100 focus:ring-white-100 mr-2 cursor-pointer rounded-lg p-2 outline-none focus:ring-2"
          @click="toggleSidebar"
        >
          <svg
            aria-hidden="true"
            class="size-6"
            :class="{ 'hidden': openSidebar && smallScreen }"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            aria-hidden="true"
            class="size-6"
            :class="{ 'hidden': !openSidebar || !smallScreen }"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <a
          href="/"
          class="mr-4 justify-between grow"
        >
          <img
            src="/images/logo.png"
            alt="ZAKAZBILETOV.KZ"
            class="h-8 w-auto m-auto"
          >
        </a>
        <div class="flex ml-auto gap-4 items-center">
          <Badge
            v-if="page.props.auth?.user.impersonated"
            severity="warning"
            class="px-2"
          >
            User Impersonated
          </Badge>
          <a
            class="ml-auto flex items-center justify-between"
            role="button"
            @click="router.delete(route('admin.logout'))"
          >
            <span class="self-center whitespace-nowrap">Выйти</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
  <!-- Sidebar -->
  <AppSidebar :open-sidebar="openSidebar" />
</template>
<script setup>
import AppSidebar from '@/Admin/Layouts/AppSidebar.vue';
import { router, usePage } from '@inertiajs/vue3';
import Badge from 'primevue/badge';
import { onMounted, onUnmounted, ref } from 'vue';

const emits = defineEmits(['toggle-sidebar']);

const page = usePage();

const smallScreen = ref(window.matchMedia('(max-width: 576px)').matches);
const openSidebar = ref(!smallScreen.value);

function toggleSidebar() {
  openSidebar.value = !openSidebar.value;
  emits('toggle-sidebar', openSidebar.value);
}

const resizeListener = () => {
  smallScreen.value = !window.matchMedia('(min-width: 576px)').matches;
};

onMounted(() => {
  window.addEventListener('resize', resizeListener);
  window.addEventListener('open-sidebar', () => {
    openSidebar.value = true;
  });
  window.addEventListener('close-sidebar', () => {
    openSidebar.value = false;
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeListener);
  window.removeEventListener('open-sidebar', () => {
    openSidebar.value = true;
  });
  window.removeEventListener('close-sidebar', () => {
    openSidebar.value = false;
  });
});
</script>
