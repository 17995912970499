import accordion from './accordion';
import autocomplete from './autocomplete';
import avatar from './avatar';
import badge from './badge';
import blockui from './blockui';
import breadcrumb from './breadcrumb';
import button from './button';
import card from './card';
import carousel from './carousel';
import cascadeselect from './cascadeselect';
import checkbox from './checkbox';
import chip from './chip';
import colorpicker from './colorpicker';
import confirmdialog from './confirmdialog';
import confirmpopup from './confirmpopup';
import contextmenu from './contextmenu';
import datatable from './datatable';
import dataview from './dataview';
import datepicker from './datepicker';
import deferred from './deferred';
import dialog from './dialog';
import divider from './divider';
import dock from './dock';
import drawer from './drawer';
import fieldset from './fieldset';
import fileupload from './fileupload';
import floatlabel from './floatlabel';
import galleria from './galleria';
import global from './global.js';
import iconfield from './iconfield';
import image from './image';
import inplace from './inplace';
import inputgroup from './inputgroup';
import inputgroupaddon from './inputgroupaddon';
import inputmask from './inputmask';
import inputnumber from './inputnumber';
import inputotp from './inputotp';
import inputtext from './inputtext';
import knob from './knob';
import listbox from './listbox';
import megamenu from './megamenu';
import menu from './menu';
import menubar from './menubar';
import message from './message';
import metergroup from './metergroup';
import multiselect from './multiselect';
import orderlist from './orderlist';
import organizationchart from './organizationchart';
import paginator from './paginator';
import panel from './panel';
import panelmenu from './panelmenu';
import password from './password';
import picklist from './picklist';
import popover from './popover';
import progressbar from './progressbar';
import progressspinner from './progressspinner';
import radiobutton from './radiobutton';
import rating from './rating';
import ripple from './ripple';
import scrollpanel from './scrollpanel';
import scrolltop from './scrolltop';
import select from './select';
import selectbutton from './selectbutton';
import skeleton from './skeleton';
import slider from './slider';
import speeddial from './speeddial';
import splitbutton from './splitbutton';
import splitter from './splitter';
import stepper from './stepper';
import tabmenu from './tabmenu';
import tabs from './tabs';
import tab from './tabs/tab';
import tablist from './tabs/tablist';
import tabpanel from './tabs/tabpanel';
import tag from './tag';
import terminal from './terminal';
import textarea from './textarea';
import tieredmenu from './tieredmenu';
import timeline from './timeline';
import toast from './toast';
import togglebutton from './togglebutton';
import toggleswitch from './toggleswitch';
import toolbar from './toolbar';
import tooltip from './tooltip';
import tree from './tree';
import treeselect from './treeselect';
import treetable from './treetable';

export default {
  global,
  directives: {
    tooltip,
    ripple,
  },
  autocomplete,
  cascadeselect,
  checkbox,
  colorpicker,
  datepicker,
  floatlabel,
  iconfield,
  inputgroup,
  inputotp,
  inputgroupaddon,
  inputmask,
  inputnumber,
  inputtext,
  knob,
  listbox,
  multiselect,
  password,
  radiobutton,
  rating,
  select,
  selectbutton,
  slider,
  textarea,
  togglebutton,
  toggleswitch,
  treeselect,
  button,
  speeddial,
  splitbutton,
  datatable,
  dataview,
  orderlist,
  organizationchart,
  paginator,
  picklist,
  tree,
  treetable,
  timeline,
  accordion,
  card,
  deferred,
  divider,
  fieldset,
  panel,
  scrollpanel,
  splitter,
  stepper,
  tabs,
  tablist,
  tab,
  tabpanel,
  toolbar,
  confirmpopup,
  confirmdialog,
  dialog,
  drawer,
  popover,
  fileupload,
  breadcrumb,
  contextmenu,
  dock,
  menu,
  menubar,
  megamenu,
  panelmenu,
  tabmenu,
  tieredmenu,
  message,
  toast,
  carousel,
  galleria,
  image,
  avatar,
  badge,
  blockui,
  chip,
  inplace,
  metergroup,
  scrolltop,
  skeleton,
  progressbar,
  progressspinner,
  tag,
  terminal,
};
