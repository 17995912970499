export default {
  root: ({ props }) => ({
    class: [
      'relative',

      // Flex & Alignment
      { 'flex flex-col': props.scrollable && props.scrollHeight === 'flex' },

      // Size
      { 'h-full': props.scrollable && props.scrollHeight === 'flex' },
    ],
  }),
  mask: {
    class: [
      // Position
      'absolute',
      'top-0 left-0',
      'z-20',

      // Flex & Alignment
      'flex items-center justify-center',

      // Size
      'w-full h-full',

      // Color
      'bg-slate-100/40 dark:bg-slate-900/40',

      // Transition
      'transition duration-200',
    ],
  },
  loadingIcon: {
    class: 'w-8 h-8 animate-spin',
  },
  tableContainer: ({ props }) => ({
    class: [
      { relative: props.scrollable, 'flex flex-col grow': props.scrollable && props.scrollHeight === 'flex' },

      // Size
      { 'h-full': props.scrollable && props.scrollHeight === 'flex' },
    ],
  }),
  header: ({ props }) => ({
    class: [
      'font-bold',

      // Shape
      props.showGridlines ? 'border-x border-t border-b-0' : 'border-y border-x-0',

      // Spacing
      'p-4',

      // Color
      'bg-slate-50',
      'border-white',
      'text-slate-700',
    ],
  }),
  table: {
    class: 'w-full border-spacing-0 border-separate',
  },
  thead: ({ context }) => ({
    class: [
      {
        'bg-white dark:bg-slate-900 top-0 z-30 sticky': context.scrollable,
      },
    ],
  }),
  tbody: ({ instance, context }) => ({
    class: [
      {
        'sticky z-20': instance.frozenRow && context.scrollable,
      },
      'bg-white dark:bg-slate-900',
    ],
  }),
  tfoot: ({ context }) => ({
    class: [
      {
        'bg-white bottom-0 z-0': context.scrollable,
      },
    ],
  }),
  footer: {
    class: [
      'font-bold',

      // Shape
      'border-t-0 border-b border-x-0',

      // Spacing
      'p-4',

      // Color
      'bg-white dark:bg-slate-900',
      'border-slate-200 dark:border-slate-700',
      'text-slate-700 dark:text-white/80',
    ],
  },
  column: {
    headerCell: ({ context, props }) => ({
      class: [
        'text-left border border-solid border-white font-bold',
        'transition duration-200',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-3', // Size
        context.sorted ? 'bg-blue-50 text-blue-700' : 'bg-slate-50 text-slate-700', // Sort
        {
          // 'sticky z-[1]': props.frozen || props.frozen === '', // Frozen Columns
          'border-x border-y': context?.showGridlines,
          'overflow-hidden space-nowrap border-y relative bg-clip-padding': context.resizable, // Resizable
        },
      ],
    }),
    columnHeaderContent: {
      class: 'flex items-center gap-2',
    },
    sort: ({ context }) => ({
      class: [context.sorted ? 'text-primary-500' : 'text-slate-700', context.sorted ? 'dark:text-primary-400' : 'dark:text-white/80'],
    }),
    bodyCell: ({ props, context, state, parent }) => ({
      class: [
        'text-left border border-solid border-white',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-3', // Size
        {
          // 'sticky bg-inherit': context.frozen || context.frozen === '', // Frozen Columns
          'border-x border-y': context?.showGridlines,
        },
      ],
    }),
    footerCell: ({ context }) => ({
      class: [
        'text-left border-0 border-b border-solid border-white font-bold',
        'bg-slate-50 text-slate-700',
        'transition duration-200',
        context?.size === 'small' ? 'p-2' : context?.size === 'large' ? 'p-5' : 'p-4', // Size
        {
          'border-x border-y': context?.showGridlines,
        },
      ],
    }),
    sortIcon: ({ context }) => ({
      class: ['ml-2', context.sorted ? 'text-inherit' : 'text-slate-700 dark:text-white/70'],
    }),
    columnFilter: {
      class: 'inline-flex items-center ml-auto w-full',
    },
    filterOverlay: {
      class: [
        'flex flex-col gap-2',

        // Position
        'absolute top-0 left-0',

        // Shape
        'border-0 dark:border',
        'rounded-md',
        'shadow-md',

        // Size
        'min-w-[12.5rem]',

        // Color
        'bg-white dark:bg-slate-900',
        'text-slate-800 dark:text-white/80',
        'dark:border-slate-700',
      ],
    },
    filterConstraintList: {
      class: 'm-0 p-0 py-3 list-none',
    },
    filterConstraint: ({ context }) => ({
      class: [
        // Font
        'font-normal',
        'leading-none',

        // Position
        'relative',

        // Shape
        'border-0',
        'rounded-none',

        // Spacing
        'm-0',
        'py-3 px-5',

        // Color
        { 'text-slate-700 dark:text-white/80': !context?.highlighted },
        { 'bg-white dark:bg-slate-900 text-slate-700 dark:text-white/80': !context?.highlighted },
        { 'bg-highlight': context?.highlighted },

        //States
        { 'hover:bg-slate-100 dark:hover:bg-[rgba(255,255,255,0.03)]': !context?.highlighted },
        { 'hover:text-slate-700 hover:bg-slate-100 dark:hover:text-white dark:hover:bg-[rgba(255,255,255,0.03)]': !context?.highlighted },
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-1 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',

        // Transitions
        'transition-shadow',
        'duration-200',

        // Misc
        'cursor-pointer',
        'overflow-hidden',
        'whitespace-nowrap',
      ],
    }),
    filterOperator: {
      class: [
        // Shape
        'rounded-t-md',

        // Color
        'text-slate-700 dark:text-white/80',
        'bg-white dark:bg-slate-700',
        '[&>[data-pc-name=pcfilteroperatordropdown]]:w-full',
      ],
    },
    filter: ({ instance }) => ({
      class: [{
        'flex items-center w-full gap-2': instance.display === 'row',
        'inline-flex ml-auto': instance.display === 'menu',
      }],
    }),
    filterRule: 'flex flex-col gap-2',
    filterButtonbar: 'flex items-center justify-between p-0',
    filterAddButtonContainer: '[&>[data-pc-name=pcfilteraddrulebutton]]:w-full',
    rowToggleButton: {
      class: [
        'relative',

        // Flex & Alignment
        'inline-flex items-center justify-center',
        'text-left',

        // Spacing
        'm-0 p-0',

        // Size
        'w-8 h-8',

        // Shape
        'border-0 rounded-full',

        // Color
        'text-slate-500 dark:text-white/70',
        'bg-transparent',
        'focus-visible:outline-none focus-visible:outline-offset-0',
        'focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',

        // Transition
        'transition duration-200',

        // Misc
        'overflow-hidden',
        'cursor-pointer select-none',
      ],
    },
    columnResizer: {
      class: [
        'block',

        // Position
        'absolute top-0 right-0',

        // Sizing
        'w-2 h-full',

        // Spacing
        'm-0 p-0',

        // Color
        'border border-transparent',

        // Misc
        'cursor-col-resize',
      ],
    },
    transition: {
      class: 'p-4 flex flex-col gap-2',
      enterFromClass: 'opacity-0 scale-y-[0.8]',
      enterActiveClass: 'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
      leaveActiveClass: 'transition-opacity duration-100 ease-linear',
      leaveToClass: 'opacity-0',
    },
  },
  bodyRow: ({ context, props, parent }) => ({
    class: [
      props?.rowClass || context.selected || context.stripedRows ? '' : 'bg-white',
      context.selected ? 'bg-blue-100 text-blue-700 dark:bg-blue-300' : 'text-gray-600',
      context.stripedRows ? (context.index % 2 === 0 ? 'bg-white text-gray-600 dark:bg-gray-900' : 'bg-gray-50 text-gray-600') : '',
      'transition duration-200',
      'focus:outline focus:outline-[0.15rem] focus:outline-blue-200 focus:outline-offset-[-0.15rem]', // Focus
      {
        'cursor-pointer': context.selectable,
        'hover:bg-gray-300/20 hover:text-gray-600': context.selectable && !context.selected, // Hover
      },
    ],
  }),
  rowExpansion: {
    class: 'bg-white dark:bg-slate-900 text-slate-600 dark:text-white/80',
  },
  rowExpansionCell: {
    class: 'bg-white text-gray-600 dark:bg-gray-900 dark:text-white/80'
  },
  rowGroupHeader: {
    class: ['sticky z-20', 'bg-white text-slate-600 dark:text-white/70', 'dark:bg-slate-900'],
  },
  rowGroupFooter: {
    class: ['sticky z-20', 'bg-white text-slate-600 dark:text-white/70', 'dark:bg-slate-900'],
  },
  rowToggleButton: {
    class: [
      'relative',

      // Flex & Alignment
      'inline-flex items-center justify-center',
      'text-left',

      // Spacing
      'm-0 p-0',

      // Size
      'w-8 h-8',

      // Shape
      'border-0 rounded-full',

      // Color
      'text-slate-500 dark:text-white/70',
      'bg-transparent',
      'focus-visible:outline-none focus-visible:outline-offset-0',
      'focus-visible:ring-1 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',

      // Transition
      'transition duration-200',

      // Misc
      'overflow-hidden',
      'cursor-pointer select-none',
    ],
  },
  rowToggleIcon: {
    class: 'inline-block w-4 h-4',
  },
  columnResizeIndicator: {
    class: 'absolute hidden w-[2px] z-20 bg-primary',
  },
};
