<template>
  <Head :title="page.props.pageTitle" />
  <ConfirmDialog />
  <Toast />
  <DynamicDialog />
  <div class="bg-gray-50 antialiased dark:bg-gray-900">
    <AppHeader @toggle-sidebar="toggleSidebar" />


    <main
      class="h-auto min-h-screen bg-gray-200 p-4 pt-20"
      :class="sidebarMargin"
    >
      <slot name="title">
        <h1 class="mb-3 text-2xl">
          {{ page.props.pageTitle }}
        </h1>
      </slot>
      <slot />
    </main>
  </div>
</template>

<script setup>
import { useDisplayFlashToast } from '@/Admin/Composables/useDisplayToast.js';
import { Head, router, usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import AppHeader from './AppHeader.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import Toast from 'primevue/toast';

const page = usePage();

useDisplayFlashToast();
router.on('finish', () => {
  if (window.matchMedia('(max-width: 576px)').matches) {
    window.dispatchEvent(new Event('close-sidebar'));
  }
});

const sidebarMargin = ref('md:ml-64');

function toggleSidebar(openSidebar) {
  sidebarMargin.value = openSidebar ? 'md:ml-64' : '';
}
</script>


