<template>
  <aside
    class="fixed left-0 top-1 z-40 h-screen w-64 pt-14 transition-transform"
    :class="{ '-translate-x-full': !openSidebar, 'translate-x-0':openSidebar }"
    aria-label="Sidenav"
  >
    <div class="h-full overflow-y-auto bg-subheader py-4">
      <PanelMenu :model="page.props.navigation">
        <template #item="{ item, root }">
          <Link
            v-if="item.url"
            :href="item.url"
            class="flex py-2"
            :class="[root ? 'px-6' : 'px-10']"
          >
            {{ item.label }}
          </Link>
          <span
            v-else
            class="flex px-6 py-2"
          >{{ item.label }}</span>
        </template>
      </PanelMenu>
    </div>
  </aside>
</template>
<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import PanelMenu from 'primevue/panelmenu';

defineProps({
  openSidebar: {
    type: Boolean,
    default: false,
  },
});
const page = usePage();
</script>
