import { router, usePage } from '@inertiajs/vue3';
import { useToast } from 'primevue/usetoast';

export function useDisplayFlashToast() {
  const toast = useToast();
  const page = usePage();

  function displayToast() {
    if (Object.keys(page.props.errors).length !== 0) {
      toast.add({
        severity: 'error',
        summary: 'Ошибка валидации!',
        detail: 'Убедитесь, что все поля заполнены правильно.',
        life: 3000,
      });
    }

    if (page.props.flash.success) {
      toast.add({
        severity: 'success',
        summary: 'Успешно!',
        detail: page.props.flash.success,
        life: 3000,
      });
    }

    if (page.props.flash.error) {
      toast.add({
        severity: 'error',
        summary: 'Ошибка!',
        detail: page.props.flash.error,
        life: 3000,
      });
    }
    if (page.props.flash.warning) {
      toast.add({
        severity: 'error',
        summary: 'Предупреждение!',
        detail: page.props.flash.warning,
        life: 3000,
      });
    }
  }

  router.on('finish', (event) => {
    if (event?.visit?.method !== 'get') {
      displayToast();
    }
  });

  // router.on('navigate', (event) => {
  //   if (!event?.visit?.method) {
  //     displayToast();
  //   }
  // });
}
