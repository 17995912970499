export default {
    content: {
        class: [
            // Spacing
            'p-0',

            // Shape
            'border-0',

            // Color
            'text-slate-700 dark:text-white/80',
            'bg-white dark:bg-slate-900'
        ]
    },
    header: {
        class: [
            'font-semibold',

            // Spacing
            'py-3 px-4',

            // Color
            'text-slate-800 dark:text-white/80',
            'bg-slate-00 dark:bg-slate-900',
            'border-b border-slate-200 dark:border-slate-700'
        ]
    }
};
